/* =========
  Rotating Announcement in Squarespace
  This Code is Licensed by Will-Myers.com
========== */

.sqs-edit-mode-active #header [data-wm-plugin="rotating-announcement"] > *:first-child{
  opacity: 1 !important;
  position: initial !important;
}
[data-wm-plugin="rotating-announcement"]:not(.loaded) > *:not(:first-child){
  display:none;
}
[data-wm-plugin="rotating-announcement"].loaded{
  height: var(--calculated-height);
  margin: 0.2rem 0;
  --speed: 0.3s;
  --z-offset: calc(var(--calculated-height) / 2);
  display: grid;
  place-items: center;
  position:relative;
}

#header [data-wm-plugin="rotating-announcement"].loaded > *{
  opacity: 0 !important;
  position: absolute;
  margin: 0 !important;
  text-align: var(--text-align, center);
  display: block;
  width:100%;
  place-self: center;
  transform-origin: center center var(--z-offset) !important;
  transition: transform var(--speed) ease !important;
  z-index:0;
}

#header [data-wm-plugin="rotating-announcement"].loaded > *.prev{
  transform: rotateX(-90deg) !important;
  opacity:1 !important;
  z-index:1
}

#header [data-wm-plugin="rotating-announcement"].loaded > *.active {
  transform: rotateX(0deg) !important;
  transition: transform var(--speed) ease !important;
  opacity:1 !important;
  z-index:2;
}

#header [data-wm-plugin="rotating-announcement"].loaded > *.next{
  transform: rotateX(90deg) !important;
  opacity:1 !important;
  transition: transform var(--speed) ease,
    opacity 0s ease var(--speed) !important;
  z-index:1
}

/*Remove Underline Animation if There is one*/
#announcement-bar-text-inner-id[data-wm-plugin="rotating-announcement"].loaded a:after{
  content: unset !important;
}
